<template>
  <div style="display: contents">
    <v-container>
      <v-snackbar v-model="snackbar" :timeout="3000" :color="color">
        {{ snackbarText }}
      </v-snackbar>

      <v-card
        class="mx-auto elevation-0 d-flex mb-8 flex-column"
        style="background-color: white; width: 100%"
      >
        <v-card-text style="width: 100%">
          <v-file-input
            outlined
            v-model="file"
            prepend-icon="mdi-camera"
            label="select an image"
          />
        </v-card-text>
        <v-card-actions style="width: 100%">
          <v-btn
            color="primary"
            @click="convertImageToPdf"
            :loading="uploading"
            style="width: 100%; height: 40px"
            v-if="!hiddenButton"
          >
            Upload
            <v-icon>mdi-upload</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { api, auth } from '../sharedPlugin';
import { toUploadFormat } from '../utils/files';
import { jsPDF } from 'jspdf';

export default {
  data: () => ({
    snackbar: false,
    title: 'Download',
    loading: false,
    snackbarText: 'The image was uploaded successfully.',
    dialog: false,
    color: 'success',
    hiddenButton: false,
    uploading: false,
    successDownload: false,
    file: null,
    device: 'ios'
  }),
  async mounted() {
    const accessToken = this.$route?.query?.token;
    const id = this.$route?.query?.id;
    this.title = this.$route?.query?.title;
    this.device = this.$route?.query?.device;

    if (accessToken) {
      this.logged(accessToken);
    }
    if (id && this.title) {
      this.getDocument(id, this.title);
    }
    this.load();
  },
  methods: {
    ...auth.mapMethods(['logged']),
    downlaodApp() {
      if (this.device === 'ios') {
        window.open(
          'https://apps.apple.com/app/microsoft-word/id586447913',
          '_blank'
        );
      } else {
        window.open(
          'https://play.google.com/store/apps/details?id=com.microsoft.office.word&hl=en&gl=US',
          '_blank'
        );
      }
    },
    getDocument(id, fileName) {
      try {
        api.Documents.getDocumentById(id).then((result) => {
          const arrayBuffer = this.base64ToArrayBuffer(result.document);

          const blob = new Blob([arrayBuffer], {
            type: 'application/octet-stream'
          });

          var a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = fileName;
          a.click();
        });
      } catch (e) {
        console.log('error', e);
      }
    },
    evaluateFileExt(fileName) {
      var file = fileName.split('.');
      fileName = file[0];
      var fileExt = file[1];
      if (fileExt === 'docx') fileExt = 'doc';
      fileName = fileName + '.' + fileExt;

      return fileName;
    },
    base64ToArrayBuffer(data) {
      const binaryString = atob(data);
      const bytes = new Uint8Array(binaryString.length);
      return bytes.map((byte, i) => binaryString.charCodeAt(i));
    },
    convertImageToPdf() {
      try {
        if (this.file.type == 'image/png' || this.file.type == 'image/jpeg') {
          this.uploading = true;

          var url = URL.createObjectURL(this.file);
          var img = new Image();

          var doc = null;

          img.addEventListener('load', (e) => {
            if (img.width > img.height) {
              doc = new jsPDF('l', 'mm', [img.width, img.height]);
              doc.addImage(img, 'png', 0, 0, img.width, img.height, '', 'SLOW');
            } else {
              doc = new jsPDF('p', 'mm', [img.width, img.height]);
              doc.addImage(img, 'png', 0, 0, img.width, img.height, '', 'SLOW');
            }

            var newDate = new Date();

            this.convertSuccess(
              doc.output('datauristring'),
              'document_' + newDate.getTime() + '.pdf'
            );
          });

          img.src = url;
        } else if (this.file.type == 'application/pdf') {
          this.upload();
        } else {
          console.log('file type not supported');
          this.color = 'error';
          this.snackbarText = 'File type not supported';
          this.snackbar = true;

          setTimeout(() => {
            this.snackbar = false;
          }, 3000);
        }
      } catch (error) {
        console.log(error);
      }
    },
    upload() {
      this.uploading = true;

      toUploadFormat(this.file, true)
        .then((res) =>
          api.Documents.uploadDocument({
            document: res,
            categoryId: this.category,
            filename: this.file.name
          })
        )
        .then((uploadResponse) => {
          this.hiddenButton = true;
          this.color = 'success';
          this.snackbarText = 'The image was uploaded successfully.';
          this.snackbar = true;

          setTimeout(() => {
            this.snackbar = false;
          }, 3000);
        })
        .finally(() => (this.uploading = false));
    },
    convertSuccess(date, name) {
      var base = date.split(',')[1];

      api.Documents.uploadDocument({
        document: base,
        categoryId: this.category,
        filename: name
      })
        .then((uploadResponse) => {
          this.snackbar = true;
          this.hiddenButton = true;
          setTimeout(() => {
            this.snackbar = false;
          }, 3000);
          this.dialog = false;
        })
        .finally(() => (this.uploading = false));
    }
  }
};
</script>
